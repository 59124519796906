import React, {useState, useEffect} from "react";
import app from "./components/base";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import HomePage from './pages/HomePage';
import LoginPage from "./pages/LoginPage";
import LogoutPage from './pages/LogoutPage'
import Pending from './pages/Pending'
import Secured from './components/Secured'
import Sidebar from "./components/sidebar/Sidebar";
import DroneList from "./pages/DroneList";
import UserConfig from "./pages/UserConfig";

export const levelContext = React.createContext(null);
export const authContext = React.createContext(null);

export default function App() {
    const [auth, setAuth] = useState(null) // auth is null until we get the token from google
    const [level, setLevel] = useState(null)
    const [isPending, setIsPending] = useState(false) //isPending is true if the user is pending approval
    useEffect(() => {
        if(!auth) return // if auth is null, we don't have a token yet
        // if we have a token, we can get the uid from the token
        const uid = auth.uid
        console.log(uid)
        // then we can use the uid to check the database to see what type of user they are
        const unsubscribe = app.firestore().collection('users').doc(uid).onSnapshot((doc) => {
            const type = doc.data().type
            switch (type) {
                case "viewer":
                    setLevel(0)
                    break;
                case "admin":
                    setLevel(1)
                    break;
                case "superUser":
                    setLevel(2)
                    break;
            
                default:
                    break;
            }
        })

        return () => {
            unsubscribe()
          }
        

    }, [auth])
    
    return (

<levelContext.Provider value={level}>
            <authContext.Provider value={auth}>
            <Router> {/*using react-router-dom to route between pages*/}
                <div className="bg-gradient-to-tr from-blue-200 to-blue-500 h-full min-h-screen w-full min-w-screen flex justify-start">
                        {(auth && !isPending) && <Sidebar level={level}/>}
                    <div className="w-full">
                    <Routes>
                        <Route exact path="/" element={<Navigate to={`/Home`}/>} />
                        <Route exact path="/login" element={<LoginPage auth={auth} setAuth={setAuth} isPending={isPending} setIsPending={setIsPending}/>}/>
                        <Route exact path="/logout" element={<LogoutPage setAuth={setAuth} auth={auth}/>}/>
                        <Route exact path="/Pending" element={<Pending/>} />

                        <Route exact path="/Home" element={<Secured auth={auth} level={level} levelReq={0}><HomePage/></Secured>}/>
                        <Route exact path="/DroneList" element={<Secured auth={auth} level={level} levelReq={0}><DroneList/></Secured>}/>
                        <Route exact path="/UserConfig" element={<Secured auth={auth} level={level} levelReq={2}><UserConfig/></Secured>}/>
                    </Routes>
                    </div>
                </div>
            </Router>
            </authContext.Provider>
            </levelContext.Provider>
    )}