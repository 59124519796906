import React, { useEffect, useState } from 'react';
import app from '../../base';
import InfoBox from './InfoBox';

export default function Miniboxes() {
    const [status, setStatus] = useState([0,0,0]);
    const [currentlyTesting, setCurrentlyTesting] = useState([]);
    useEffect(() => {
        const unsub2 = app.firestore().collection('config').doc('globalVars').onSnapshot(doc => {
            setCurrentlyTesting(doc.data().currentlyTesting)
        })
        const unsub = app.firestore().collection('speedtests').onSnapshot(snapshot => {
            let alertDrones = 0
            let offlineDrones = 0
            let nominalDrones = 0
            snapshot.docs.forEach(doc => {
                const droneData = doc.data();
                const status = droneData.status;
                if(status === 0) {
                    offlineDrones++
                } else if(status === 1) {
                    alertDrones++
                } else if(status === 2) {
                    alertDrones++
                } else {
                    console.log('error')
                // TODO: handle error
                }
            })
            setStatus([nominalDrones, alertDrones, offlineDrones])
        }, err => {
            console.log(`Encountered error: ${err}`);
          });

      return () => {
        unsub()
        unsub2()

      };
    }, []);
    
  return(
  <div className='flex justify-around'>
  <InfoBox text="Alert Drones" number={status[1]}/>
  <InfoBox text="Offline Drones" number={status[2]}/>
  <InfoBox  text="Drones Currently Testing" number={currentlyTesting.length}/>
</div>)
}
