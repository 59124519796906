import app from '../base'
export default function getDroneList(setUserList, setDropdownList, isPending) {
    const comparison = isPending ? '==' : '!='
    let data = []
    let dropdownData = []

    return app.firestore().collection('users').where("type", comparison, "pending").onSnapshot(querySnapshot => {
        querySnapshot.forEach((doc) => {
            let uid = doc.id
            if(uid === app.auth().currentUser.uid) return //skip current user
            let email = doc.data().email
            let type = doc.data().type
            let name = doc.data().name
            if(isPending) {
                data.push([name, email])
            } else {
                data.push([name, email, type])
            }
            dropdownData.push([uid, email, type])
        });
        setUserList([...data])
        setDropdownList([...dropdownData])
        data = []
        dropdownData = []
    })
}
