import React, {useState, useEffect} from 'react'
import DronesGraph from '../components/HomePage/DronesGraph'
import DronesTable from '../components/HomePage/DronesTable'
import Miniboxes from '../components/HomePage/miniboxes/Miniboxes'
import getDroneStatusData from '../components/HomePage/getDroneStatusData'



export default function HomePage() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const unsub = getDroneStatusData(setData)
        
        return () => {
          unsub()
        }
      }, [setData])
    return (
        <div>
                    <Miniboxes/>
                    <DronesGraph data={data}/>
                    <DronesTable data={data}/>
        </div>
    )
}

/* Main homepage, contains all the widgets, the data from the drones is requested here and passed down to the widgets*/