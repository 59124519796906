import React from 'react'

export default function MiniBox(props) {
    const center = props.center
    const css = center ? "bg-blue-300 h-screen flex items-center justify-center" : "w-full"
    return (
        <div className={css}>
            <div className="bg-white border border-gray-400 shadow-lg flex flex-col items-center p-10 m-4 rounded-md">
                {props.children}
            </div>
        </div>
    )
}
