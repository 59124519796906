import React from 'react'
import MiniBox from '../styled/MiniBox'

export default function Loading() {
    return (
        <div>
            <MiniBox className="text-2xl" center={true}>
                Loading
            </MiniBox>
        </div>
    )
}
/*loading box while waitng for the token from google*/
//TODO: add a loading animation while waiting for the token from google
