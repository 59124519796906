import React from 'react'
import RelayStatus from './RelayStatus'

export default function PinNormal(props) {
    const {data} = props

  return (
    <div className="flex justify-center mb-5">
      <RelayStatus data={data} pinName={"pin2"}/>
    </div>

  )
}
