import React, {useState} from 'react'

export default function DetailsEditMode(props) {
    const {data, setChangedData} = props
    const [name, setName] = useState(data.name)
    const [location, setLocation] = useState(data.location)
    const [refreshPeriod, setRefreshPeriod] = useState((data.refreshTime) / 60000)
    const [pingLimit, setPingLimit] = useState(data.pingLimit)
    const [uploadLimit, setUploadLimit] = useState(data.uploadLimit)
    const [downloadLimit, setDownloadLimit] = useState(data.downloadLimit)

    function handleNameChange(e) {
        setName(e.target.value)
        setChangedData(prev => ({...prev, name: e.target.value}))
    }
    function handleLocationChange(e) {
        setLocation(e.target.value)
        setChangedData(prev => ({...prev, location: e.target.value}))
    }
    function handleRefreshPeriodChange(e) {
        setRefreshPeriod(e.target.value)
        setChangedData(prev => ({...prev, refreshTime: e.target.value * 60000}))
    }
    function handlePingLimitChange(e) {
        setPingLimit(e.target.value)
        setChangedData(prev => ({...prev, pingLimit: e.target.value}))
    }
    function handleUploadLimitChange(e) {
        setUploadLimit(e.target.value)
        setChangedData(prev => ({...prev, uploadLimit: e.target.value}))
    }
    function handleDownloadLimitChange(e) {
        setDownloadLimit(e.target.value)
        setChangedData(prev => ({...prev, downloadLimit: e.target.value}))
    }

    

  return (
    <>
    <div className='text-left w-1/3 flex justify-center'>
            <table className='w-1/2'>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td className='text-right'><input onChange={handleNameChange} value={name} className='border text-right'/></td>
                    </tr>
                    <tr>
                        <td>Location</td>
                        <td className='text-right'><input onChange={handleLocationChange} value={location} className='border text-right'/></td>
                    </tr>
                    <tr>
                        <td>Refresh Period</td>
                        <td className='text-right'><input onChange={handleRefreshPeriodChange} value={refreshPeriod} className='border text-right'/></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className='text-left w-1/3 flex justify-center'>
            <table className='w-1/2'>
                <tbody>
                    <tr>
                        <td>Ping Limit</td>
                        <td className='text-right'><input onChange={handlePingLimitChange} value={pingLimit} className='border w-20 text-right'/></td>
                    </tr>
                    <tr>
                        <td>Up Limit</td>
                        <td className='text-right'><input onChange={handleUploadLimitChange} value={uploadLimit} className='border w-20 text-right'/></td>
                    </tr>
                    <tr>
                        <td>Down Limit</td>
                        <td className='text-right'><input onChange={handleDownloadLimitChange} value={downloadLimit} className='border w-20 text-right'/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
  )
}
