export default function submitUpdatedDetails(mac, updatedDetails, uid, idToken) {
    //TODO local validation
    console.log(updatedDetails)
    let url = ""
    Object.keys(updatedDetails).forEach(key => {
        url += `&${key}=${updatedDetails[key]}`
    })  
    url += `&deviceMAC=${mac}`
    url += `&uid=${uid}`
    url += `&idToken=${idToken}`
    const fullUrl = encodeURI(`https://ts2server.tamir.uk/editDrone?${url}`)
    console.log(fullUrl)
    
    fetch(fullUrl, {
  "method": "GET",
  "headers": {}
    })
    .then(response => {
    console.log(response);
    })
    .catch(err => {
    console.error(err);
    });
}
