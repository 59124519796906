import React, { useState, useContext } from 'react'
import handleUserChange from './handeUserChange'
import handleUserDelete from './handleUserDelete'
import { authContext } from '../../App'
import app from '../base'

export default function UserEditDropdown(props) {
    const auth = useContext(authContext)
    const {dropdownData} = props
    const targetUid = dropdownData[0]
    const role = dropdownData[2]
    console.log(dropdownData)
    const [hasBeenEdited, setHasBeenEdited] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectVal, setSelectVal] = useState(role)
    const userType = role
    const saveStyle = hasBeenEdited ? "bg-blue-400" : "bg-gray-400"
    function handleChange(e) {
        setSelectVal(e.target.value)
        setHasBeenEdited(e.target.value !== userType)
    }

    function handleSave() {
        setLoading(true)
        const changeTo = selectVal
        app.auth().currentUser.getIdToken().then(idToken => {
            handleUserChange(targetUid, changeTo, auth.uid, idToken).then(res => {
                console.log(res)
                setLoading(false)

            })
        })
        setHasBeenEdited(false)
    }

    function handleCancel() {
        setHasBeenEdited(false)
        setSelectVal(userType)
    }

    function handleDelete() {
        setLoading(true)
        app.auth().currentUser.getIdToken().then(idToken => {
            handleUserDelete(targetUid, auth.uid, idToken).then(res => {
                console.log(res)
                setLoading(false)
            })
        })
    }
  return (
    <div className='flex justify-center'>
        <select onChange={handleChange} value={selectVal} className='bg-gray-200 p-2 rounded-xl'>
            <option value='viewer'>Viewer</option>
            <option value='admin'>Drone Admin</option>
            <option value='superUser'>Super User</option>
        </select>
        <button onClick={handleSave} className={saveStyle+ ' text-white p-2 ml-5 rounded-xl'}>Save</button>
        <button onClick={handleCancel} className='bg-blue-400 text-white p-2 ml-5 rounded-xl'>Cancel</button>
        <button onClick={handleDelete} className='bg-red-400 text-white p-2 ml-5 rounded-xl'>Delete User</button>
        
        {loading && <svg className="animate-spin h-6 w-6 mt-2 ml-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>}
    </div>

  )
}
