export default async function submitUserData(name, email, uid, idToken) {
    //TODO local validation
    let url = ""
    url += `name=${name}&email=${email}&uid=${uid}&idToken=${idToken}`
    const fullUrl = encodeURI(`https://ts2server.tamir.uk/userLogin?${url}`)
    console.log(fullUrl)
    
    const req = await fetch(fullUrl, {
  "method": "GET",
  "headers": {}
    })

    const res = await req.json()
    console.log(res)
    return res.isPending

    //TODO: check for errors
}
