import app from "../base"

export default function getDroneList(mac, setDroneData) {
  return app.firestore().collection('speedtests').doc(mac).onSnapshot(doc => {
    if(!doc.exists) {
        console.log('No such document!')
    }
    const data = doc.data()
    console.log(data)
    setDroneData(data)
  })
}