import app from "../base"
import Alert from "./statusIcons/Alert"
import Nominal from "./statusIcons/Nominal"
import Offline from "./statusIcons/Offline"
import Unknown from "./statusIcons/Unknown"

export default function getDroneList(setDroneList, setDropdownList) {
  const icons = [ <Offline/>, <Alert/>, <Nominal/>, <Unknown/>]
  return app.firestore().collection('speedtests').onSnapshot(querySnapshot => {
    let droneList = []
    let dropdownList = []
    querySnapshot.forEach(doc => {
      const data = doc.data()
      const droneMAC = data.deviceMAC
      const droneName = data.name
      const droneStatus = icons[data.status]
      const latestTest = data.latestTest
      const droneLocation = data.location

      const ping = latestTest.ping
      const upload = latestTest.upload
      const download = latestTest.download

      const latestTestTime = latestTest.timestamp
      const latestTestTimeDate = new Date(latestTestTime)
      const latestTestTimeDateFormatted = latestTestTimeDate.toLocaleString()

      const droneRelayStatusData = data?.pinStatus?.pin2?.pinVal
      let droneRelayStatus = icons[3]
      if(droneRelayStatusData === true) {
        droneRelayStatus = icons[2]
      } else if (droneRelayStatusData === false) {
        droneRelayStatus = icons[0]
      }

      const droneData = [droneName, droneMAC, droneLocation, droneRelayStatus, ping, upload, download, latestTestTimeDateFormatted, droneStatus]
      droneList.push(droneData)
      dropdownList.push(droneMAC)
    })
    setDroneList(droneList)
    setDropdownList(dropdownList)
  })
}
