import React from 'react'

export default function RelayStatus(props) {
    const {data, pinName} = props
    const pinVal = () => {
      if(data.pinStatus !== undefined) {
        const pinTimestamp = parseInt(data.pinStatus[pinName].timestamp)
        const pinTimestampDate = new Date((pinTimestamp))
        const pinTimestampDateFormatted = pinTimestampDate.toLocaleString()
        return (data.pinStatus[pinName].pinVal ? ['bg-green-300', 'On', pinTimestampDateFormatted] : ['bg-red-300', 'Off', pinTimestampDateFormatted])
      }
      else {
        return ['bg-gray-300', '...']
      }
    }
  return (
    <div className='justify-around items-center flex'>
        <p className='font-bold'>Status:</p>
        <div className={pinVal()[0] + ' rounded-lg px-5 py-1 ml-3'}>
            {pinVal()[1]}
        </div>
        <p className='ml-3'>{pinVal()[2]}</p>
    </div>
  )
}
