import React from 'react';
import MiniBox from '../styled/MiniBox';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Drone Status Over Time',
    },
  },
};

export default function DronesGraph(props) {
  const data = props.data
  let labels = []
  let nominalDrones = []
  let totalDrones = []
  let alertDrones = []
  let offlineDrones = []
  data.forEach(item => {
    const quickEntry = item[0]
    const timestamp = quickEntry[0]
    const totalDronesNow = quickEntry[1]
    const nominalDronesNow = quickEntry[2]
    const alertDronesNow = quickEntry[3]
    const offlineDronesNow = quickEntry[4]
    labels.push(timestamp)

    nominalDrones.push(nominalDronesNow)
    totalDrones.push(totalDronesNow)
    alertDrones.push(alertDronesNow)
    offlineDrones.push(offlineDronesNow)
  })

  //all data is currently newest to oldest, needs to be reversed

  labels = labels.reverse()
  labels = labels.map(item => {
    const time = new Date(item)
    return time.toLocaleTimeString('en-UK') //conversion to human readable time
  })

  nominalDrones = nominalDrones.reverse()
  totalDrones = totalDrones.reverse()
  alertDrones = alertDrones.reverse()
  offlineDrones = offlineDrones.reverse()

  const finalData = {
    labels,
    datasets: [
      {
        label: 'Total Drones',
        data: totalDrones,
        borderColor: 'rgb(0, 0, 255)',
        backgroundColor: 'rgba(0, 0, 255, 1)',
      },
      {
        label: 'Alert Drones',
        data: alertDrones,
        borderColor: 'rgb(255, 165, 0)',
        backgroundColor: 'rgba(255, 165, 0, 1)',
      },
      {
          label: 'Offline Drones',
          data: offlineDrones,
          borderColor: 'rgb(255, 0, 0)',
          backgroundColor: 'rgba(255, 0, 0, 1)',
        },
    ],
  };
  return (
  <MiniBox className="p-4 mx-4">
      <Line options={options} data={finalData} />
  </MiniBox>)
}