import React, {useEffect} from 'react'
import app from '../components/base'
import MiniBox from '../components/styled/MiniBox'

export default function Pending() {
    useEffect(() => {
        if(!app.auth().currentUser) {
            window.location.href = "/"
        }
        const unsubscribe = app.firestore().collection('users').doc(app.auth().currentUser.uid).onSnapshot((doc) => {
            if(doc.data().type !== "pending") {
                window.location.href = "/"
            }
        })
        return () => {
            unsubscribe()
        }
    }, [])

  return (
    <div>
        <MiniBox center={true}>
            <h1 className="text-2xl font-bold">Pending</h1>
            <p className="text-xl mt-3">Your account is pending approval. You will be redirected when you are approved.</p>
        </MiniBox>
    </div>
  )
}
