import React from 'react'
import DroneListTable from '../components/DroneList/DroneListTable'

export default function DroneList() {
  return (
    <div>
      <DroneListTable/>
    </div>
  )
}
