import React, { useState } from 'react';

export default function CustomTableRow(props) {
    const {data, CustomDropdown, dropdownData} = props
    const [isOpen, setIsOpen] = useState(false)
    const hasDropDown = CustomDropdown && dropdownData
    function toggleDropDown() {
        setIsOpen(prev => !prev)
    }

  return <>
  <tr>
    {hasDropDown && 
  <td className='grid justify-center'>
    <button onClick={toggleDropDown}>
    <svg className="my-4 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path className='rotate-90' strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M19 9l-7 7-7-7" : "M9 5l7 7-7 7"}></path>
    </svg>
    </button>
  </td>}
  {data.map((entry, i) => {
    return <td key={i}>{entry}</td>
  })}
  </tr >
  {hasDropDown && 
  <tr>
      <td colSpan="9" className='w-full'> {/*TODO: make this a adapt to amount of columns*/}
              {isOpen && (<>
              <CustomDropdown dropdownData={dropdownData} className="bg-blue-300 w-full"/>
          </>)}
      </td>
  </tr>}
  
</>;
}
