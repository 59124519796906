import React, {useState, useEffect} from 'react'
import getDroneData from './getDroneData'
import Details from './droneDropdown/Details'

export default function DroneListDropdown(props) {
    const {dropdownData} = props
    const mac = dropdownData

    const [droneData, setDroneData] = useState([[]])
    useEffect(() => {
        const unsub = getDroneData(mac, setDroneData)
    
      return () => {
        unsub()
      }
    }, [setDroneData, mac])
    

  return (
    <div className='w-full'>
      <Details data={droneData}/>
    </div>

  )
}
