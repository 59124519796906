import React from 'react';
import DroneListSection from './DroneListSection';

export default function DronesList(props) {
  const data = props.dropdownData
  let namedDrones = data.slice(3, 6)
  return <>
      {namedDrones.map((drones, i) => {
          const types = ["Nominal", "Alert", "Offline"]
          const type = types[i]
            return <div key={i}>
                {drones && (<>
                          <h1 className='font-normal text-2xl text-left pl-4'>{type} Drones</h1>
                          <div className='h-1 bg-gray-400 w-1/3 rounded-full mb-5'></div>
                          <DroneListSection drones={drones}/>
                </>)}
            </div>
      })}
  </>;
}
