import React from 'react'
import DroneListIcon from './icons/DroneListIcon'
import HomeIcon from './icons/HomeIcon'
import UserConfigIcon from './icons/UserConfigIcon'
import SidebarElement from './SidebarElement'
import LogoutIcon from './icons/LogoutIcon'
import SwitchSpeedtest from './SwitchSpeedtest'

export default function Sidebar(props) {
    
    let sidebars = [["Home", HomeIcon, "/Home"], ["Drone List", DroneListIcon, "/DroneList"]]
    if(props.level === 2) {
        sidebars.push(["User Config", UserConfigIcon, "/UserConfig"])
    }
    let allowToggle = false
    console.log(props.level)
    if (props.level >= 1) {
        allowToggle = true
    }
    sidebars.push(["Logout", LogoutIcon, "/logout"])
        
    return (
            <aside className="w-48 min-h-screen shadow-2xl" aria-label="Sidebar">
                <div className="overflow-y-auto h-full py-4 px-3 bg-gray-50 rounded dark:bg-gray-800">
                        <span
                            className="self-center text-lg font-semibold whitespace-nowrap dark:text-white">TS-2</span>
                    <ul className="space-y-2">
                        {sidebars.map(([name, Icon, path], key) => (
                            <SidebarElement key={key} name={name} Icon={Icon} path={path}/>
                        ))}
                    </ul>
                    <ul className='mt-10'>
                        <SwitchSpeedtest allowEdit={allowToggle}/>
                    </ul>
                </div>
            </aside>
    )
}
