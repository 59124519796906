import React, {useState, useEffect} from 'react'
import CustomTable from '../styled/CustomTable'
import DroneListDropdown from './DroneListDropdown'
import getDroneList from './getDroneList'

export default function DroneListTable() {
  const fields = ["Details","Name", "MAC", "Location", "Relay Status", "Ping", "Upload (mbps)", "Download (mbps)", "Last Update Time", "Status"]
  
  const [droneList, setDroneList] = useState([[]])
  const [dropdownList, setDropdownList] = useState([[]])

  useEffect(() => {
    const unsub = getDroneList(setDroneList, setDropdownList)

    return () => {
      unsub()
    }
  }, [setDroneList])
  
  return (
    <CustomTable fields={fields} data={droneList} CustomDropdown={DroneListDropdown} dropdownData={dropdownList} pageSize={20}/>
  )
}
