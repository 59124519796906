export default async function handleUserCange(targetUid, uid, idToken) {
    //TODO local validation
    let url = `targetUid=${targetUid}`
    url += `&uid=${uid}`
    url += `&idToken=${idToken}`
    
    const fullUrl = encodeURI(`https://ts2server.tamir.uk/deleteUser?${url}`)
    console.log(fullUrl)
    
  await fetch(fullUrl, {
  "method": "GET",
  "headers": {}
    })
}
