import React, {useState, useContext, useEffect} from 'react'
import { authContext } from '../../App'
import handleSwitchSpeedtest from './handleSwitchSpeedtest'
import app from '../base'

export default function SwitchSpeedtest(props) {
    const allowEdit = props.allowEdit
    const [loading, setLoading] = useState(false)
    const [switchButton, setSwitchButton] = useState(false)

    useEffect(() => {
        const unsub = app.firestore().collection('config').doc('globalVars').onSnapshot(doc => {
            console.log(doc.data())
            setSwitchButton(doc.data().allowSpeedtest)
        })
        return () => unsub()
    }, [])

    
    
    const auth = useContext(authContext)
    const handleClick = () => {
        if(!allowEdit) {
            return
        }
        setLoading(true)
        handleSwitchSpeedtest(auth).then((data) => {
            setSwitchButton(data)
            setLoading(false)
        })
    }

    const style = switchButton ? "bg-blue-500 justify-end" : "bg-red-400"
    return (
        <div className='grid content-between w-full'>
            <div className='flex justify-center'>
                <div onClick={handleClick} className={'transition ease-in-out duration-150 w-12 h-7 border-2 border-gray-400 rounded-full flex ' + style}>
                <div className='w-6 h-6 bg-gray-400 rounded-full'>
                </div>
            </div>
        </div>
        <div className='grid text-center mt-3 justify'>
            {allowEdit ? <p>Enable Drone speedtests</p> : <p>Drone Speedtests Status<br/><h5 className='text-gray-400'>Switch disabled</h5></p>}
            <div className='flex justify-center'>

            {loading && <svg className="animate-spin -ml-1 h-6 w-6 mt-3 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>}
            </div>
        </div>
        
        </div>
    )
}

