import app from '../base'

export default async function handleSwitchSpeedtest (auth){

    const idToken = await app.auth().currentUser.getIdToken()
    let url = ""
    url += `&uid=${auth.uid}`
    url += `&idToken=${idToken}`
    const fullUrl = encodeURI(`https://ts2server.tamir.uk/toggleDroneSpeedtest?${url}`)
    console.log(fullUrl)
    
    const response = await fetch(fullUrl, {
  "method": "GET",
  "headers": {}
    })
    const data = await response.json()

    if(data.state) {
        return data.state
    } else {
        console.log("error")
    }
}