import React from 'react'
import { Link } from 'react-router-dom'

export default function SidebarElement(props) {
const {name, Icon, path} = props
  return (
    <li>
      <Link to={path}
              className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <Icon/>
                <span className="ml-3">{name}</span>
      </Link>
</li>
  )
}
