import React from 'react'
import {Navigate} from 'react-router-dom'
export default function Secured(props) {
    const {auth, level, levelReq} = props
    if (level < levelReq) {
        return <Navigate to="/login" />
    }

    return (
        <div>
            {auth ? (props.children) : (<Navigate
                to={"/login"}/>)}
        </div>
    )
}
/*all pages that are wrapped in this component are marked as secured, if there is no auth state,
it means that the user is not logged in, therefore we redirect to the login page*/