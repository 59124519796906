import React, {useContext} from 'react'
import { levelContext } from '../../../App'

export default function DetailsNormal(props) {
    const {data} = props
    const level = useContext(levelContext)
    const allow = level >= 1
    let width = allow ? 'w-1/3' : 'w-1/2'
  return (
    <>
    <div className={'text-left flex justify-center ' + width}>
            <table className='w-1/2'>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td className='text-right'>{data.name}</td>
                    </tr>
                    <tr>
                        <td>Location</td>
                        <td className='text-right'>{data.location}</td>
                    </tr>
                    <tr>
                        <td>Refresh Period</td>
                        <td className='text-right'>{(data.refreshTime) / 60000}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className={'text-left flex justify-center ' + width}>
            <table className='w-1/2'>
                <tbody>
                    <tr>
                        <td>Ping Limit</td>
                        <td className='text-right'>{data.pingLimit}</td>
                    </tr>
                    <tr>
                        <td>Up Limit</td>
                        <td className='text-right'>{data.uploadLimit}</td>
                    </tr>
                    <tr>
                        <td>Down Limit</td>
                        <td className='text-right'>{data.downloadLimit}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
  )
}
