 import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"

const app = firebase.initializeApp({
    apiKey: "AIzaSyCcdlKFG94B-_7K7aAxA78i_bg7XcN-6MI",
    authDomain: "teleport-speedtest-2.firebaseapp.com",
    projectId: "teleport-speedtest-2",
    storageBucket: "teleport-speedtest-2.appspot.com",
    messagingSenderId: "1051289297911",
    appId: "1:1051289297911:web:1c19517cb9fed7cc71c4f2",
    measurementId: "G-4XK9DZGL4V"
  });

export default app

//this file contains the firebase configuration for the app, this is to handle login and database access