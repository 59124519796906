export default function submitStartSpeedtestNow(mac, uid, idToken) {
    //TODO local validation
    let url = ""  
    url += `&deviceMAC=${mac}`
    url += `&uid=${uid}`
    url += `&idToken=${idToken}`
    const fullUrl = encodeURI(`https://ts2server.tamir.uk/specificDroneSpeedtest?${url}`)
    console.log(fullUrl)
    
    return fetch(fullUrl, {
  "method": "GET",
  "headers": {}
    })
}