import React from 'react';
import MiniBox from '../../styled/MiniBox';
export default function infoBox(props) {
  const {text, number} = props
  return (
    <MiniBox>
        <div className="flex flex-col">
        <h1 className="text-gray-500 font-bold text-xl ">{text}</h1>
        <h1 className='text-2xl m-auto'>{number}</h1>
        </div>

    </MiniBox>
  )
}