
export default function dataProcess(databaseData) {
    console.log(databaseData)
    const historicalData = databaseData.data ? databaseData.data : []
    const processedData = historicalData.map((record) => {
        const timestamp = Object.getOwnPropertyNames(record)[0]
        const ping = record[timestamp].ping
        const upload = record[timestamp].upload
        const download = record[timestamp].download
        const intTimestamp = parseInt(timestamp)
        const time = new Date(intTimestamp).toLocaleDateString('en-UK') + ' ' + new Date(intTimestamp).toLocaleTimeString('en-UK')
        return [time, ping, upload, download]
    })
    const colorList = ['#FF0000', '#00FF00', '#0000FF']
    const idList = ['Ping', 'Upload', 'Download']
    let finaldata = []
    for (let i = 0; i < 3; i++) {
        finaldata.push({
            id: idList[i],
            color: colorList[i],
            data: processedData.map((record) => {
                return {x: record[0], y: record[i + 1]}
            })
        })
    }
    return finaldata
}
