import app from "../base"

export default function getDroneStatusData(setData) {
    const currentTime = new Date(Date.now())
    const month = currentTime.getUTCMonth() + 1
    const recordName = `${currentTime.getUTCFullYear()}-${month}-${currentTime.getUTCDate()}`
    return app.firestore().collection('archives').doc(recordName).onSnapshot(doc => {
        if(!doc.exists) {
            console.log('no data')
            return
            // TODO: handle error
        }
        const data = doc.data()
    const droneStatusData = data.droneStatus
    const fullArchiveData = []

    droneStatusData.forEach(entry => {
        let totalDronesNum = 0
        let alertDronesNum = 0
        let offlineDronesNum = 0
        let nominalDronesNum = 0
        let alertDronesMAC = []
        let offlineDronesMAC = []
        let nominalDronesMAC = []
        let alertDrones = []
        let offlineDrones = []
        let nominalDrones = []

        const timestamp = entry.timestamp
        const droneList = JSON.parse(entry.drones)
        totalDronesNum = droneList.length
        droneList.forEach(drone => {
            const status = drone[2]
            const droneMAC = drone[0]
            const droneName = drone[1]
            if(status === 0) {
                offlineDronesNum += 1
                offlineDronesMAC.push(droneMAC)
                offlineDrones.push(droneName)
            } else if(status === 1) {
                alertDronesNum += 1
                alertDronesMAC.push(droneMAC)
                alertDrones.push(droneName)
            } else if(status === 2) {
                nominalDronesNum += 1
                nominalDronesMAC.push(droneMAC)
                nominalDrones.push(droneName)
            } else {
                console.log("Error: status is not 0, 1, or 2")
                // TODO: handle error
            }
        })

        const finalData = [timestamp, totalDronesNum, nominalDronesNum, alertDronesNum, offlineDronesNum]
        const finalLists = [nominalDronesMAC, alertDronesMAC, offlineDronesMAC, nominalDrones, alertDrones, offlineDrones]
        fullArchiveData.push([finalData, finalLists])
    })
    fullArchiveData.sort((a, b) => (a[0][0] > b[0][0]) ? -1 : 1) //sort newest to oldest
    setData(fullArchiveData)
    })
}
