import React, { useState, useContext } from 'react'
import { authContext } from '../../App'
import app from '../base'
import handleUserChange from './handeUserChange'
import handleUserDelete from './handleUserDelete'

export default function PendingUsersDropdown(props) {
    const auth = useContext(authContext)
    const {dropdownData} = props
    const targetUid = dropdownData[0]
    const [loading, setLoading] = useState(false)

    function handleAccept() {
      setLoading(true)
      app.auth().currentUser.getIdToken().then(idToken => {
        handleUserChange(targetUid, "viewer", auth.uid, idToken).then(res => {
          console.log(res)
          setLoading(false)
        })
    })
    }

    function handleReject() {
      setLoading(true)
      app.auth().currentUser.getIdToken().then(idToken => {
        handleUserDelete(targetUid, auth.uid, idToken).then(res => {
          console.log(res)
          setLoading(false)
        })
    })
    }
  return (
    <div className='flex justify-center'>
        <button onClick={handleAccept} className={'bg-blue-400 text-white p-2 ml-5 rounded-xl'}>Accept user</button>
        <button onClick={handleReject} className='bg-red-400 text-white p-2 ml-5 rounded-xl'>Reject User</button>
        {loading && <svg className="animate-spin h-6 w-6 mt-2 ml-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>}
    </div>

  )
}