export default async function handleUserChange(targetUid, changeTo, uid, idToken) {
    //TODO local validation
    let url = ""
    url += `targetUid=${targetUid}`
    url += `&changeTo=${changeTo}`
    url += `&uid=${uid}`
    url += `&idToken=${idToken}`
    
    const fullUrl = encodeURI(`https://ts2server.tamir.uk/changeRole?${url}`)
    console.log(fullUrl)
    
   return await fetch(fullUrl, {
  "method": "GET",
  "headers": {}
    })
}
