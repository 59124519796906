import React, {useState, useContext} from 'react'
import DetailsEditMode from './DetailsEditMode'
import DetailsNormal from './DetailsNormal'
import DroneGraph from './DroneGraph/DroneGraph'
import DroneTable from './DroneTable'
import PinControl from './pinControl/PinControl'
import submitUpdatedDetails from './submitUpdatedDetails'
import submitDeleteDrone from './submitDeleteDrone'
import { authContext, levelContext } from '../../../App'
import app from '../../base'
import submitStartSpeedtestNow from './submitStartSpeedtestNow'

export default function Details(props) {
    const {data} = props
    const [isEditMode, setIsEditMode] = useState(false)
    const [changedData, setChangedData] = useState({})
    const [speedtestLoading, setSpeedtestLoading] = useState(false)
    const auth = useContext(authContext)

    function enableEditMode() {
        setIsEditMode(true)
    }
    function handleCancel() {
        setIsEditMode(false)
    }

    function handleSave() {
        setIsEditMode(false)
        setChangedData({})
        app.auth().currentUser.getIdToken().then(idToken => {
            submitUpdatedDetails(data.deviceMAC, changedData, auth.uid, idToken)
        })

    }

    function handleDelete() {
        setIsEditMode(false)
        app.auth().currentUser.getIdToken().then(idToken => {
            submitDeleteDrone(data.deviceMAC, auth.uid, idToken).then(() => {
                window.location.reload()
            })
        })
    }

    function handleDownload() {
        app.auth().currentUser.getIdToken().then(idToken => {
            let url = ""
            url += `deviceMAC=${data.deviceMAC}`
            url += `&uid=${auth.uid}`
            url += `&idToken=${idToken}`
            window.location.href = `https://ts2server.tamir.uk/DroneExport.csv?${url}`
        })
    }

    function handleStartSpeedtest() {
        setSpeedtestLoading(true)
        app.auth().currentUser.getIdToken().then(idToken => {
            submitStartSpeedtestNow(data.deviceMAC, auth.uid, idToken).then(() => {
                setInterval(() => {
                    setSpeedtestLoading(false)
                }, 300);
            })
        })
    }

    const level = useContext(levelContext)
    const allow = level >= 1

  return (
    <div>
    <div className='flex justify-start pb-10'>
        {(allow) && <div className='w-1/3 p-4'>
            {
                isEditMode ?
                <div className='h-full w-full'>
                    {
                        Object.keys(changedData).length ? //if there are any elements in the changed data object
                        <button className='h-full w-1/3 rounded-lg text-white bg-blue-500 mr-5' onClick={handleSave}>Save</button>
                        :
                        <button className='h-full w-1/3 rounded-lg text-white bg-gray-300 mr-5'>Save</button>
                    }
                    <button className='h-full w-1/3 rounded-lg text-white bg-blue-500' onClick={handleCancel}>Cancel</button>
                    <button className='w-2/3 mt-5 p-2 rounded-lg text-white bg-red-500' onClick={handleDelete}>Delete</button>
                </div> :
                <button onClick={enableEditMode} className='h-full w-full rounded-lg bg-blue-700 text-white'>Edit</button>
            }
        </div>}
        {isEditMode ? <DetailsEditMode data={data} setChangedData={setChangedData}/> : <DetailsNormal data={data}/>}
    </div>

    <button className="bg-blue-700 p-2 rounded-lg text-white" onClick={handleDownload}>Download CSV</button>
    {allow && <button className="bg-blue-700 p-2 rounded-lg text-white ml-5" onClick={handleStartSpeedtest}>{speedtestLoading ?  <p>Running speedtest...</p> :<p>Start Speedtest Now</p>}</button>}
    {allow && <p className='text-gray-400 mt-3'>Speedtest should finish less than a minute after being started</p>}

    <div className='w-full  mt-10'>
        {data.data ? 
        <>
        <PinControl data={data} isEditMode={isEditMode}/>
        {data.data.length ? 
        <>
        <DroneTable data={data}/>
        <DroneGraph data={data}/>
        </> : <div className='text-center text-2xl pl-12'>No data newer than a week</div>}
        </>
         : 
         <div className='flex justify-center pl-12'>
            <svg className="animate-spin -ml-1 h-12 w-12 mt-3 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
         <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
 </svg>
 </div>}
    </div>

</div>
  )
}
