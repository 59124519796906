import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'tailwindcss/dist/base.min.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://b005c6cffbbf485287219e24231298c5@o385307.ingest.sentry.io/4504134002343936",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
<React.StrictMode>
  <App />
</React.StrictMode>, document.getElementById("root"));
