import React, {useState, useEffect} from 'react'
import CustomTable from '../components/styled/CustomTable'
import UserEditDropdown from '../components/UserConfig/UserEditDropdown'
import getUserData from '../components/UserConfig/getUserData'
import PendingUsersDropdown from '../components/UserConfig/PendingUsersDropdown'
import MiniBox from '../components/styled/MiniBox'

export default function UserConfig() {
  const fields = ["Edit","Name", "User Email", "User Role"]
  const pendingFields = ["Edit","Name", "User Email"]

  const [pendingList, setPendingList] = useState([[]])
  const [pendingDropdownList, setPendingDropdownList] = useState([[]])

  const [userList, setUserList] = useState([[]])
  const [dropdownList, setDropdownList] = useState([[]])

  useEffect(() => {
    const unsub = getUserData(setUserList, setDropdownList, false)
    const unsub2 = getUserData(setPendingList, setPendingDropdownList, true)

    return () => {
      unsub()
      unsub2()
    }
  }, [setUserList, setDropdownList])

  return (
    <>
    {userList.length || pendingList.length ?
    <div>
      <CustomTable title="Current Users" fields={fields} CustomDropdown={UserEditDropdown} dropdownData={dropdownList} data={userList} pageSize={10}/>
      {pendingList[0] && <CustomTable title="Pending Users" fields={pendingFields} CustomDropdown={PendingUsersDropdown} dropdownData={pendingDropdownList} data={pendingList} pageSize={10}/>}
    </div> : <MiniBox center={true}>
    <svg className="animate-spin -ml-1 h-12 w-12 mt-3 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
         <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
  <p className='mt-5'>There are no pending or other operating users</p>
      </MiniBox>}
    </>
  )
}
/*TODO: user config page*/
