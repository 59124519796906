import React, { useState } from 'react';
import MiniBox from './MiniBox';
import CustomTableRow from './CustomTableRow';

export default function CustomTable(props) {
  const {fields, data, CustomDropdown, dropdownData, pageSize, title} = props
  const hasDropDown = CustomDropdown && dropdownData
  const [pageNum, setPageNum] = useState(1)

  function pageChange(interator, specificPage) {
      const totalPageNum = Math.ceil(data.length/pageSize)
      const pageIsUnderZero = (pageNum === 1 && interator === -1)
      const pageIsOverTotal = (pageNum === totalPageNum && interator === 1)
      if(pageIsOverTotal || pageIsUnderZero) {
        return
      }
      if(specificPage < 0 || specificPage > totalPageNum) {
        return
      }

      if(specificPage){
        setPageNum(specificPage)
        return
      }

      setPageNum(prev => prev + interator)
    }
  return (
    <MiniBox>
      {title && <h1 className='mb-5 text-2xl'>{title}</h1>}
    <table className='w-full text-center table-auto text-gray-600'>
      <thead className="bg-gray-200">
        <tr>
          {fields.map((field, i) => {
            return <th key={i}>{field}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((entry, i) => {
          if(i >= (pageNum - 1)*pageSize && i < pageNum*pageSize) {
            return (<CustomTableRow data={entry} key={i} dropdownData={hasDropDown && dropdownData[i]} CustomDropdown={hasDropDown && CustomDropdown}/>)
          } else {
            return null
          }})}

        </tbody>
    </table>
    <div className="flex justify-left">
    <button className="bg-gray-200 p-2 rounded-xl" onClick={() => pageChange(-1)}>Last page</button>
    <p className='px-10 py-2'>{pageNum}</p>
    <button className="bg-gray-200 p-2 rounded-xl" onClick={() => pageChange(1)}>Next page</button>
    </div>
          
  </MiniBox>
  );
}
