import React from 'react'
import CustomTable from '../../styled/CustomTable'

export default function DroneTable(props) {
  const data = props.data
  let finaldata = []
  if(data.data){
    for (let i = 0; i < data.data.length; i++) {
      const timestamp = Object.getOwnPropertyNames(data.data[i])[0]
      const ping = data.data[i][timestamp].ping
      const upload = data.data[i][timestamp].upload
      const download = data.data[i][timestamp].download
      const intTimestamp = parseInt(timestamp)
      const time = new Date(intTimestamp).toLocaleDateString('en-UK') + ' ' + new Date(intTimestamp).toLocaleTimeString('en-UK')
      finaldata.push([time, ping, upload, download])
    }
  }
  finaldata = finaldata.reverse()
  const fields = ['Time', 'Ping', 'Upload', 'Download']

  return (
    <>
    <CustomTable fields={fields} data={finaldata} pageSize={10}/>
        </>

  )

}
