import React, {useEffect} from 'react'
import firebase from 'firebase';
import { Navigate } from 'react-router-dom';

export default function LogoutPage(props) {
    const {setAuth, auth} = props
    useEffect(() => {
        firebase.auth().signOut().then(() => {
            // Sign-out successful
            console.log("logged out")
            setAuth(null)
          }).catch((error) => {
            // An error happened.
          });
    }, [setAuth])
    return (
        <div>
            {auth ? <span/> : <Navigate to="/login"/>}
        </div>
    )
}
/*this page renders when the user logs out, it removes the auth state and signs the user out from google
then redirects to the login page, while it is waiting, it displays and empty span tag*/
