import React, {useEffect, useState} from 'react'
import app from '../components/base'
import firebase from "firebase";
import {Navigate} from "react-router-dom";
import LoginBox from "../components/LoginPage/LoginBox";
import Loading from "../components/LoginPage/Loading";
import submitUserDetails from "../components/LoginPage/submitUserData";

export default function LoginPage(props) {
    const {setAuth, auth, isPending, setIsPending} = props
    const [loading, setLoading] = useState(true) //loading process in place while we are waiting for the token from google

    const provider = new firebase
    .auth
    .GoogleAuthProvider()

useEffect(() => {
    const unsubscribe = firebase
        .auth()
        .onAuthStateChanged(function (user) { //when we get the token from google back from the redirect
            if (user) { //if we have a token
                user.getIdToken().then(idToken => {
                    console.log(idToken);
                    submitUserDetails(user.displayName, user.email, user.uid, idToken).then(isPending => {
                        console.log("pending: " + isPending)
                        setIsPending(isPending) //set isPending to the value returned from the server
                        setAuth(user) //log the user in
                    }) //submit the user details to the database
                })
                
            } else {
                setAuth(false); //if not log the user out
                setLoading(false) //stop the loading process
            }
        });
    return () => {
        unsubscribe()
    }
}, [setAuth, setIsPending])

app
    .auth()
    .getRedirectResult()
    .catch(function (error) {
        console.log(error) //if there is an error, print in the console
    });

function signin() {
    app
        .auth()
        .signInWithRedirect(provider) //redirect to the google login page
}

    return (
        <div>
            {auth
                ? (isPending ? <Navigate to="/pending"/> : <Navigate to="/"/>) //if the user is logged in redirect to the home page
                : (loading ? <Loading/> : <LoginBox signin={signin}/>)/*if no auth, it could be that we are still waitng for google,
            if we are not waiting, show the login box*/} 
        </div>
    )
}
