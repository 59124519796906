import React from 'react';
import CustomTable from '../styled/CustomTable';
import DronesList from './DronesList';

export default function DronesTable(props) {
    const data = props.data
  const fields = ["Details", "Time", "Date", "Total Drones", "Nominal Drones", "Alert Drones", "Offline Drones"]

const newData = data.map(item => {

  let row = item[0]
  const time = new Date(row[0])
  const fullDate = time.toLocaleDateString('en-UK')
  const timeString = time.toLocaleTimeString('en-UK')
  const newRow = [timeString, fullDate, row[1], row[2], row[3], row[4]]
  return newRow
})

const dataForDropdown = data.map(item => {
    return item[1]
})
  
  return (
    <CustomTable fields={fields} data={newData} CustomDropdown={DronesList} dropdownData={dataForDropdown} pageSize={10}/>
  )
}


//TODO write documentation for this mess